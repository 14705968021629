<template>
  <div
      :class="{active: isActive}"
      class="gallery-image-container">
    <pinch-zoom>
      <img
          v-if="!!galleryAsset"
          :src="image"
          ref="galleryImage"
          :data-placeholder="galleryAsset.placeholder.src"
          :data-src="galleryAsset.galleryImage.src"
          :alt="galleryAsset.galleryImage.title">
    </pinch-zoom>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'GalleryImage',
  props: {
    galleryAsset: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      fullyLoaded: false,
      image: null,
      loading: false
    }
  },
  computed: {
    ...mapState('gallery', {
      activeId: 'activeId',
      itemsToLoad: 'itemsToLoad',
      loadedItems: 'loadedItems'
    }),
    isActive() {
      return !!this.activeId && this.activeId === this.galleryAsset.id
    }
  },
  watch: {
    itemsToLoad(newItemsToLoad) {
      if (this.fullyLoaded || this.loading) {
        return
      } else if (newItemsToLoad[0] === this.galleryAsset.id) {
        // If it's the first item in the array, that means it's the active gallery item; load it immediately.
        this.loadGalleryImage()
        return
      }

      // Check to see if the item is ready to be loaded based of the queue of itemsToLoad.
      this.readyForQueueLoad()
    },
    loadedItems() {
      if (this.fullyLoaded || this.loading) {
        return
      }

      this.readyForQueueLoad()
    }
  },
  methods: {
    ...mapActions('gallery', {
      setLoadedItem: 'setLoadedItem'
    }),
    loadGalleryImage() {
      if (this.fullyLoaded || this.loading) return

      this.loading = true

      // Make the placeholder image (non-4k) the initial image, and begin loading the galleryImage
      this.image = this.$refs.galleryImage.dataset.placeholder

      // Load the gallery image in a dynamically created img element
      const imageEl = document.createElement('img')

      imageEl.addEventListener('load', () => {
        this.image = this.$refs.galleryImage.dataset.src
        this.fullyLoaded = true
        this.setLoadedItem(this.galleryAsset.id)
      })

      imageEl.src = this.$refs.galleryImage.dataset.src
    },
    readyForQueueLoad() {
      if (!this.itemsToLoad.includes(this.galleryAsset.id) || this.fullyLoaded || this.loading) return

      const indexInItemsToLoad = this.itemsToLoad.findIndex(id => id === this.galleryAsset.id)

      const indexesLoaded = this.itemsToLoad.map(id => this.loadedItems.includes(id))

      if (indexesLoaded.splice(0, indexInItemsToLoad).every(boolVal => !!boolVal)) {
        // All the items before this one have been loaded, load this item
        this.loadGalleryImage()
      }
      // console.log(indexInItemsToLoad, indexesLoaded, indexesLoaded.splice(0, indexInItemsToLoad))
    }
  }
}
</script>

<style lang="scss" >
.gallery-image-container {
  @apply h-full;
  @apply overflow-hidden;
  @apply opacity-0;
  z-index: -1;
  transition: all 0.3s linear;

  &.active {
    @apply opacity-100;
    z-index: 1;
  }

  .pinch-zoom-wrapper {
    @apply h-full;

    .pinch-zoom-content {
      @apply bg-transparent;
      cursor: url(~@/assets/cursor-white.svg) 14 16,auto;
    }
  }
  img {
    @apply max-w-full;
    @apply max-h-full;
  }
}
</style>