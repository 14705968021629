<template>
  <div
      :class="{active: isActive}"
      class="gallery-video-container">
    <template v-if="!!galleryAsset">
      <img
          :src="image"
          ref="galleryVideoPlaceholder"
          :data-src="galleryAsset.placeholder.src"
          :alt="galleryAsset.placeholder.title">
      <vimeo-player
          v-if="loading || fullyLoaded"
          class="vimeo-player loading"
          ref="player"
          :key="vimeoKey"
          :video-id="galleryAsset.galleryVideo.vimeoVideoId"
          :player-width="$refs.galleryVideoPlaceholder.offsetWidth"
          :player-height="placeholderHeight"
          @loaded="videoLoaded"/>
    </template>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'GalleryVideo',
  props: {
    galleryAsset: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      fullyLoaded: false,
      image: null,
      loading: false,
      placeholderHeight: 0,
      vimeoReloadTimeout: null,
      vimeoKey: 0
    }
  },
  computed: {
    ...mapState('gallery', {
      showGallery: 'show',
      activeId: 'activeId',
      itemsToLoad: 'itemsToLoad',
      loadedItems: 'loadedItems',
    }),
    isActive() {
      return !!this.activeId && this.activeId === this.galleryAsset.id
    }
  },
  watch: {
    itemsToLoad(newItemsToLoad) {
      if (this.fullyLoaded || this.loading) {
        return
      } else if (newItemsToLoad[0] === this.galleryAsset.id) {
        // If it's the first item in the array, that means it's the active gallery item; load it immediately.
        this.loadGalleryImage()
        return
      }

      // Check to see if the item is ready to be loaded based of the queue of itemsToLoad.
      this.readyForQueueLoad()
    },
    loadedItems() {
      if (this.fullyLoaded || this.loading) {
        return
      }

      this.readyForQueueLoad()
    },
    isActive(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.$refs.player.pause()
      }
    },
    showGallery(newVal) {
      if (!newVal && this.isActive) {
        this.$refs.player.pause()
      }
    }
  },
  mounted() {
    // A key we can add to, to remount the vimeo player, to re-trigger loading.
    this.vimeoKey = parseInt(this.galleryAsset.id.toString() + '0')

    window.addEventListener('resize', () => {
      if (!this.fullyLoaded && !this.loading) return

      this.placeholderHeight = this.$refs.galleryVideoPlaceholder.offsetHeight
      this.resetVimeoContainer()
    })
  },
  methods: {
    ...mapActions('gallery', {
      setLoadedItem: 'setLoadedItem',
    }),
    loadGalleryImage() {
      if (this.fullyLoaded || this.loading) return

      this.loading = true

      // Show the image as a placeholder while we wait for the iframe to load
      this.image = this.$refs.galleryVideoPlaceholder.dataset.src

      setTimeout(() => {
        this.placeholderHeight = this.$refs.galleryVideoPlaceholder.offsetHeight
      }, 200)
    },
    readyForQueueLoad() {
      if (!this.itemsToLoad.includes(this.galleryAsset.id) || this.fullyLoaded || this.loading) return

      const indexInItemsToLoad = this.itemsToLoad.findIndex(id => id === this.galleryAsset.id)

      const indexesLoaded = this.itemsToLoad.map(id => this.loadedItems.includes(id))

      if (indexesLoaded.splice(0, indexInItemsToLoad).every(boolVal => !!boolVal)) {
        // All the items before this one have been loaded, load this item
        this.loadGalleryImage()
      }
      // console.log(indexInItemsToLoad, indexesLoaded, indexesLoaded.splice(0, indexInItemsToLoad))
    },
    videoLoaded() {
      this.$refs.galleryVideoPlaceholder.classList.add('video-loaded')
      this.$refs.player.$el.classList.remove('loading')
    },
    resetVimeoContainer() {
      this.$refs.galleryVideoPlaceholder.classList.remove('video-loaded')
      this.$refs.player.$el.classList.add('loading')

      // Wait a half second before trying to reload.
      clearTimeout(this.vimeoReloadTimeout)
      this.vimeoReloadTimeout = setTimeout(() => {
        // Changing the key will remount the vimo player container, triggering the loaded event again, and associating
        // an appropriate video container size.
        this.vimeoKey += 1
      }, 1000)

    }
  },
}
</script>

<style lang="scss">
.gallery-video-container {
  @apply relative;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply h-full;
  @apply overflow-hidden;
  @apply opacity-0;
  z-index: -1;
  transition: all 0.3s linear;

  &.active {
    @apply opacity-100;
    z-index: 1;
  }

  img, .vimeo-player {
    @apply absolute;
    @apply max-w-full;
    @apply max-h-full;
    @apply w-full;
  }

  img {
    opacity: 1;
    z-index: 1;
    transition: all 0.6s linear;

    &.video-loaded {
      opacity: 0;
      z-index: -1;
    }
  }

  .vimeo-player {
    background-color: transparent;
    transition: all 0.6s linear;
    opacity: 1;
    z-index: 1;

    &.loading {
      z-index: -1;
      opacity: 0;
    }

    iframe {
      background-color: transparent;
    }
  }
}
</style>