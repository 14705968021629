<template>
  <img
      ref="image"
      src=""
      :class="{'lazy-loading': !showImage}"
      :data-src="imageSource"
      :alt="altText"
      @click="$emit('click')">
</template>

<script>
export default {
  name: 'LazyImage',
  props: {
    imageSource: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      required: false,
      default: ''
    },
    container: {
      type: Element,
      required: true
    },
    load: {
      type: Boolean,
      required: false,
      // default: false
    },
    readyToShow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      showImage: false
    }
  },
  watch: {
    load: function() {
      this.loadAsset()
    },
    readyToShow: function(newVal) {
      if (newVal) {
        this.toggleShowImage()
      }
    }
  },
  mounted() {
    this.listenToPageEvents()
    this.loadAsset()

  },
  methods: {
    listenToPageEvents() {
      this.container.addEventListener('scroll', this.toggleShowImage)
      window.addEventListener('resize', this.toggleShowImage)
    },
    loadAsset() {
      // Hasn't been told to load yet, or is already loaded.
      if (this.load === false || this.loaded === true) return

      this.$refs.image.addEventListener('load', this.imageLoaded)
      this.$refs.image.src = this.$refs.image.dataset.src
    },
    imageLoaded() {
      this.loaded = true
      this.$emit('loaded')
      this.toggleShowImage()
    },
    toggleShowImage() {
      // Not loaded, row not ready, or already showing; then return
      if (!this.loaded || !this.readyToShow || this.showImage) return

      const bottomOfWindow = window.innerHeight + this.container.scrollTop - (window.innerHeight / 3)

      if(!!this.$refs.image && bottomOfWindow >= this.$refs.image.offsetTop) {
        this.showImage = true
        setTimeout(() => {
          this.$emit('imageShowing')
          // 700 seconds, to wait for the the animations for opacity and top position on the image element.
        }, 700)
        this.removeEventListeners()
      }
    },
    removeEventListeners() {
      this.container.removeEventListener('scroll', this.toggleShowImage)
      window.removeEventListener('resize', this.toggleShowImage)
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  @apply relative bg-transparent;
  transition: all .7s ease-in-out;
  transform: translateY(0px);// scale(1) rotate(0deg);

  &.lazy-loading {
    @apply opacity-0;
    cursor: url(~@/assets/cursor-dark.svg) 14 16, auto;
    transform: translateY(150px);// scale(1) rotate(0deg);
  }
}
</style>