<template>
  <div
      :class="{show: show, closed: closed}"
      class="gallery-container">
    
    <div
        :class="{active: show}"
        class="close-gallery"
        @click="closeGallery">
      <div class="bun"></div>
      <div class="bun"></div>
    </div>

    <div class="asset-container">
      <template v-for="(galleryAsset, index) in galleryAssets">
        <GalleryImage
            v-if="galleryAsset.galleryImage !== undefined"
            :key="index"
            class="gallery-item"
            v-touch:swipe.left="nextSlide"
            v-touch:swipe.right="previousSlide"
            :gallery-asset="galleryAsset"/>
         <GalleryVideo
             v-else-if="galleryAsset.galleryVideo !== undefined"
             :key="index"
             class="gallery-item"
             v-touch:swipe.left="nextSlide"
             v-touch:swipe.right="previousSlide"
             :gallery-asset="galleryAsset"/>
      </template>
    </div>

    <div
        class="carrot carrot-left"
        @click="previousSlide">
      <img src="@/assets/svg/left-arrow.svg" alt="Go To Previous Image">
    </div>
    <div
        class="carrot carrot-right"
        @click="nextSlide">
      <img src="@/assets/svg/right-arrow.svg" alt="Go To Next Image">
    </div>

    <div class="progression-container">
      <div class="current-slide">{{ currentSlide }}</div>
      <div class="divider"></div>
      <div class="total-slides">{{ totalSlides }}</div>
    </div>

  </div>
</template>

<script>
import GalleryImage from '@/components/gallery/GalleryImage'
import GalleryVideo from '@/components/gallery/GalleryVideo'
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name: 'GalleryContainer',
  components: {GalleryImage, GalleryVideo},
  props: {
    galleryAssets: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      closed: true,
    }
  },
  computed: {
    ...mapState('gallery', {
      show: 'show'
    }),
    ...mapGetters('gallery', {
      currentSlide: 'currentSlide',
      totalSlides: 'totalSlides'
    })
  },
  watch: {
    galleryAssets(newVal) {
      if (!newVal) return

      this.applyItemIds(this.galleryAssets)
    },
    show(newVal) {
      if (!newVal) return

      this.updateItemsToLoad()
      this.closed = false
    }
  },
  methods: {
    ...mapActions('gallery', {
      toggleGalleryShow: 'toggleGalleryShow',
      applyItemIds: 'applyItemIds',
      updateItemsToLoad: 'updateItemsToLoad',
      nextSlide: 'nextSlide',
      previousSlide: 'previousSlide'
    }),
    closeGallery() {
      this.toggleGalleryShow()
      // Wait for the 0.3s css animation, then mark closed to change z-index.
      setTimeout(() => {
        this.closed = true
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-container {
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-screen;
  @apply overflow-hidden;
  @apply opacity-0;
  @apply transition-opacity;
  @apply duration-300;
  @apply ease-linear;
  padding: 30px 0;
  background-color: #000;
  cursor: url(~@/assets/cursor-white.svg) 14 16,auto;

  &.show {
    @apply opacity-100;
    z-index: 1000;
  }
  &.closed {
    z-index: -9999;
  }

  * {
    @apply bg-transparent;
    cursor: url(~@/assets/cursor-white.svg) 14 16,auto;
  }

  .close-gallery {
    @apply absolute;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply flex-col;
    @apply transition-all;
    @apply duration-300;
    @apply ease-in;
    top: 33px;
    right: 24px;
    width: 24px;
    height: 10px;
    z-index: 5;

    &.active {
      height: 18px;
      top: 29px;
      right: 20px;

      .bun:nth-of-type(1) {
        transform: rotate(45deg);
      }
      .bun:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }

    .bun {
      @apply relative;
      @apply transition-all;
      @apply duration-300;
      @apply ease-in;
      @apply origin-left;
    }

    .bun {
      @apply w-full;
      @apply h-px;
      background-color: #ffffff;
    }


  }

  .asset-container {
    @apply grid;
    @apply bg-transparent;
    @apply h-full;
    padding: 0 6px;

    .gallery-item {
      grid-column: 1;
      grid-row: 1;
    }
  }

  .carrot {
    @apply absolute;
    @apply top-0;
    @apply bottom-0;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply opacity-0;
    width: 30px;
    z-index: 3;

    &.carrot-left {
      @apply left-0;
    }

    &.carrot-right {
      @apply right-0;
    }
  }

  .progression-container {
    @apply text-center;
    @apply fixed;
    z-index: 1;
    color: #545454;
    top: unset;
    bottom: 15px;
    right: 26px;
    font-family: 'BlackerDisplay', serif;

    & > div {
      font-size: 30px;
      line-height: 30px;
    }

    .divider {
      @apply w-full;
      @apply h-px;
      background-color: #545454;
    }

    .total-slides {
      padding-top: 6px;
    }
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    @apply block;

    .close-gallery {
      width: 31px;
      height: 13px;
      top: 37px;
      right: 40px;

      &.active {
        height: 23px;
        right: 35px;
        top: 32px;
      }
    }

    .asset-container {
      padding: 0 13px;
    }

    .progression-container {
      top: 70%;
      right: 30px;
      bottom: unset;

      & > div {
        font-size: 64px;
        line-height: 64px;
      }

      .total-slides {
        padding-top: 16px;
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: 1300px){
    padding: 30px 40px;

    .close-gallery {
      top: 37px;
      right: 37px;

      &.active {
        top: 32px;
        right: 32px;
      }
    }

    .asset-container {
      padding: 0 150px;
    }

    .carrot {
      @apply flex;
      @apply opacity-100;
      width: 60px;

      &.carrot-left {
        padding-left: 36px;
      }

      &.carrot-right {
        padding-right: 36px;
      }
    }

    .progression-container {
      //right: 40px;
      //bottom: 51px;

      & > div {
        font-size: 80px;
        line-height: 82px;
      }

      .total-slides {
        padding-top: 20px;
      }
    }
  }

}
</style>