<template>
  <div :class="[{enabled: enabled}, 'zoom-container']">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'ZoomContainer',
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-container.enabled {
  @apply max-w-full;

  &:not(.loading) {
    @apply overflow-hidden;

    &:hover img {
      transform: scale(1.025);
    }
  }

  img {
    @apply w-full;
    transition: all .7s ease-in-out;
    transform: scale(1) rotate(0deg);
  }
}
</style>